exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-art-gallery-tsx": () => import("./../../../src/pages/artGallery.tsx" /* webpackChunkName: "component---src-pages-art-gallery-tsx" */),
  "component---src-pages-characters-serin-tsx": () => import("./../../../src/pages/characters/serin.tsx" /* webpackChunkName: "component---src-pages-characters-serin-tsx" */),
  "component---src-pages-characters-tiberius-tsx": () => import("./../../../src/pages/characters/tiberius.tsx" /* webpackChunkName: "component---src-pages-characters-tiberius-tsx" */),
  "component---src-pages-characters-tsx": () => import("./../../../src/pages/characters.tsx" /* webpackChunkName: "component---src-pages-characters-tsx" */),
  "component---src-pages-external-links-tsx": () => import("./../../../src/pages/externalLinks.tsx" /* webpackChunkName: "component---src-pages-external-links-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mini-projects-dice-roller-tsx": () => import("./../../../src/pages/miniProjects/diceRoller.tsx" /* webpackChunkName: "component---src-pages-mini-projects-dice-roller-tsx" */)
}

